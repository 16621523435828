import React, { useState } from "react";
import { BiChevronUpCircle, BiChevronDownCircle } from "react-icons/bi";
import { RiSearchLine } from "react-icons/ri";
/* global BigInt */

import {
  company1,
  binanceNetwork,
  company2,
  company3,
  company4,
} from "../../../images/images";
import SingleProject from "./SingleProject/SingleProject";
import styles from "./AllProject.module.css";
import { useAccount, useSigner } from "wagmi";
import { ethers } from "ethers";
const AllProject = () => {
  const { data: signer } = useSigner();
  const { address } = useAccount();

  const [unlocked, setUnlocked] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);
  const [isClaimed, setisClaimed] = useState(false);
  const [totalUnclaimed, settotalUnclaimed] = useState(0);
  const [price, setPrice] = useState(0);
  let idoAddress = "0x42536a4b05d21Fbc8eC9e51223150b0afbF24D82";
  let idoAbi = [
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "purchaser",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "TokensPurchased",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "Whitelisted",
      "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "buyers",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "calculateUserTokens",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "checkWhitelist",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "contribute",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "endTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getTotalRaised",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getWhitelistLength",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "hardcap",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "isBuyer",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "isLive",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "isWhitelistRound",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "isWhitelisted",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "minimimumPurchase",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "publicCap",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "rate",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "saleToken",
      "outputs": [
        {
          "internalType": "contract IERC20",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_hardcap",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_softcap",
          "type": "uint256"
        }
      ],
      "name": "setHardcap",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_min",
          "type": "uint256"
        }
      ],
      "name": "setMinimumPurchase",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_start",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_end",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_wlEnd",
          "type": "uint256"
        }
      ],
      "name": "setTimes",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "softcap",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "startTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "token",
      "outputs": [
        {
          "internalType": "contract IERC20",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSold",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "userContributions",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "userPurchase",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "userTotalContributions",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "whitelist",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "whitelistCap",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "whitelistEndTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "whitelistLength",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "whitelists",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "withdrawETH",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "withdrawSaleToken",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "xbjdjwkkde",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ]

  let arb_provider = new ethers.providers.JsonRpcProvider("https://rpc.ankr.com/arbitrum");
  let bsc_provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org");

  let inferAbi = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "balance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        }
      ],
      "name": "calculateRewards",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "claim",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "claimCount",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "cycle",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "emergencyWithdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "getClaimPerCycle",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "initialize",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "isInitialized",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "lastClaimed",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "maxCycle",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "token",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "totalClaimed",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_token",
          "type": "address"
        }
      ],
      "name": "updateToken",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address[]",
          "name": "_users",
          "type": "address[]"
        },
        {
          "internalType": "uint256[]",
          "name": "_balances",
          "type": "uint256[]"
        }
      ],
      "name": "updateUsersAndBalances",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ]
  let hash_provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org");
  const getunlocked = async () => {
    try {
      let inferAddress = "0xE3ccbF51ec5E501231975f3Ea07eF8d70c35b59B";
      let infer = new ethers.Contract(inferAddress, inferAbi, arb_provider);
      let total = await infer.calculateRewards(address);

      let ido = new ethers.Contract(idoAddress, idoAbi, bsc_provider);
      let totalPurchased = BigInt(await ido.userTotalContributions(address)) * BigInt(167);

      let initial6percent = totalPurchased * BigInt(625) / BigInt(10000);
      let initial25percent = totalPurchased * BigInt(2500) / BigInt(10000);

      let oldClaim = initial25percent + initial6percent;

      let newClaim = BigInt(await infer.totalClaimed(address));

      console.log(newClaim, "newClaim")
      console.log(oldClaim, "oldClaim")
      console.log(totalPurchased, "totalPurchasedr")

      let unclaimed = BigInt(totalPurchased) - BigInt(oldClaim) - BigInt(newClaim);
      console.log(unclaimed, "unclaimed")
      // let totalUnclaimed = await infer.totalUnclaimed(address);
      // let Sixpercent = totalPurchased * 0.0625;
      // let twentyfivepercent = totalPurchased * 0.25;
      // let percentages = BigInt(Sixpercent) + BigInt(twentyfivepercent);
      // totalUnclaimed = BigInt(totalUnclaimed);
      // console.log(totalPurchased, "totalPurchased")
      // console.log(totalUnclaimed, "totalUnclaimed")
      // console.log(Sixpercent, "Sixpercent")
      // console.log(twentyfivepercent, "twentyfivepercent")
      // console.log(percentages, "percentages")


      // let final = BigInt(totalPurchased) - BigInt(totalUnclaimed) - percentages;

      settotalUnclaimed(ethers.utils.formatEther(unclaimed));


      setTotalPurchase(ethers.utils.formatEther(totalPurchased));
      // // console.log(ethers.utils.formatEther(totalC));
      setUnlocked(ethers.utils.formatEther(total));
    } catch (e) {
      console.log(e)
    }
  };

  // const getIsClaimed = async () => {
  //   let inferAddress = "0x4D843cD8541744ba678645bDF0c6054B487999eb";
  //   let infer = new ethers.Contract(inferAddress, inferAbi, arb_provider);
  //   try {
  //     let isClaimed = await infer.isClaimed(address);
  //     console.log(isClaimed)
  //     setisClaimed(isClaimed);
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }



  const claim = async () => {
    let arb_provider = new ethers.providers.JsonRpcProvider("https://rpc.ankr.com/arbitrum");
    let inferAddress = "0xE3ccbF51ec5E501231975f3Ea07eF8d70c35b59B";
    let infer = new ethers.Contract(inferAddress, inferAbi, signer);
    try {
      return await infer.claim();
    } catch (e) {
      console.log(e)
    }
  }

  const getHTEPrice = async () => {
    let url = "https://api.coingecko.com/api/v3/simple/price?ids=hepton&vs_currencies=usd"
    const res = await fetch(url)
    const data = await res.json()
    console.log(data["hepton"].usd)
    setPrice((data["hepton"].usd))
  }

  React.useEffect(() => {
    getunlocked();
    // getIsClaimed();
    getHTEPrice();
  }, [address, signer]);
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("Active Vesting");
  const allSortByItems = [
    "Active Vesting",
    "Ended Vesting",
    "Claimed Vesting",
    "unclaimed vesting",
  ];
  const [dropDown, setDropDown] = useState(false);
  const projects = [
    {
      logo: "https://pbs.twimg.com/profile_images/1624393158456143874/tdEGrujS_400x400.jpg",
      name: "Hepton L3",
      symbol: "HTE",
      status: { text: "Active Project", value: "Active vesting" },
      remainingToken: totalPurchase * 167,
      tokenPrice: 1,
      claimOn: { logo: "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png", name: "Arbitrum Network" },
      unclaimedValue: totalUnclaimed,
      unlockNext: "January 8 2024 05:30:01",
      vestingEnds: "April 27 2024 05:30:01",
      availableTokens: {
        bnb: unlocked,
        usd: (unlocked * price),
      },
      func: claim,
      claimable: {
        text: unlocked <= 0 ? "No Vesting" : !isClaimed ? "Claim Vesting" : "Vesting Claimed",
        value: unlocked <= 0 ? false : !isClaimed ? true : false,
      },
    },
    // {
    //   logo: company2,
    //   name: "CRYPTO Project",
    //   status: { text: "Active Project", value: "active vesting" },
    //   remainingToken: 0,
    //   tokenPrice: 1,
    //   claimOn: { logo: binanceNetwork, name: "Binance Network" },
    //   unclaimedValue: 5000,
    //   unlockNext: "May 01 2023 05:30:01",
    //   vestingEnds: "May 01 2023 05:30:01",
    //   availableTokens: {
    //     bnb: 5806,
    //     usd: 5000,
    //   },
    //   claimable: {
    //     text: "Claimed Vesting",
    //     value: true,
    //   },
    // },
    // {
    //   logo: company3,
    //   name: "CRYPTO Project",
    //   status: { text: "Active Project", value: "active vesting" },
    //   remainingToken: 0,
    //   tokenPrice: 1,
    //   claimOn: { logo: binanceNetwork, name: "Binance Network" },
    //   unclaimedValue: 5000,
    //   unlockNext: "May 01 2023 05:30:01",
    //   vestingEnds: "May 01 2023 05:30:01",
    //   availableTokens: {
    //     bnb: 586,
    //     usd: 5000,
    //   },
    //   claimable: {
    //     text: "Claimed Vesting",
    //     value: true,
    //   },
    // },
    // {
    //   logo: company4,
    //   name: "CRYPTO Project",
    //   status: { text: "Active Project", value: "active vesting" },
    //   remainingToken: 0,
    //   tokenPrice: 1,
    //   claimOn: { logo: binanceNetwork, name: "Binance Network" },
    //   unclaimedValue: 5000,
    //   unlockNext: "May 01 2023 05:30:01",
    //   vestingEnds: "May 01 2023 05:30:01",
    //   availableTokens: {
    //     bnb: 0,
    //     usd: 0,
    //   },
    //   claimable: {
    //     text: "Unclaimed Vesting",
    //     value: false,
    //   },
    // },
  ];
  const sortedProjects = projects
    .filter((project, _) => {
      const statusValue = project.status.value.toLowerCase();
      const claimable = project.claimable.text.toLowerCase();
      const newProjects =
        statusValue === sortBy.toLocaleLowerCase() ||
        claimable === sortBy.toLowerCase();

      return newProjects;
    })
    .filter((project, _) =>
      project.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className={styles.allProject}>
          <div className={styles.titleAndHeader}>
            <h4 className={styles.title}>All your projects</h4>
            <div className={styles.searchAndFilter}>
              <div className={styles.inputContainer}>
                <RiSearchLine className={styles.searchIcon} />
                <input
                  type="text"
                  className={styles.input}
                  name="search"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search projects"
                />
              </div>
              <div className={styles.filter}>
                <div
                  className={styles.dropdown}
                  style={{
                    background: dropDown && "#242b33",
                    borderRadius: dropDown && "20px",
                  }}
                >
                  <p className={styles.sortValue}>
                    {" "}
                    <span className={styles.sort}>Sort by: </span>{" "}
                    <span className={styles.sortBy}>{sortBy}</span>
                  </p>
                  {dropDown ? (
                    <BiChevronUpCircle
                      className={styles.arrow}
                      onClick={() => setDropDown((prev) => !prev)}
                    />
                  ) : (
                    <BiChevronDownCircle
                      className={styles.arrow}
                      onClick={() => setDropDown((prev) => !prev)}
                    />
                  )}
                  {dropDown && (
                    <div className={styles.dropDownItems}>
                      {allSortByItems.map((el, i) => (
                        <p
                          key={i}
                          className={styles.dropDownItem}
                          onClick={() => {
                            setDropDown(false);
                            setSortBy(el);
                          }}
                        >
                          {el}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.projects}>
            {sortedProjects.map((el, i) => (
              <SingleProject {...el} key={i} price={price} totalUnclaimed={totalUnclaimed} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProject;
