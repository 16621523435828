import React from "react";
import FindProject from "../components/Home/FindProject/FindProject";
import HeroSection from "../components/Home/HeroSection/HeroSection";
import Token from "../components/Minter/Token/Token";





const Home = () => {
  return (
    <div>
      <HeroSection />
      <FindProject />
      <Token />
    </div>
  );
};

export default Home;
